import React from "react"
import Layout from "./components/layout"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

import TelesisLogo from "../images/logos/telesis_logo.svg"
import InterregLogo from "../images/logos/interreg_europe_logo.svg"
import HfaLogo from "../images/logos/hfa_hoch_Logo_.svg"
import WkoLogo from "../images/logos/WKV4C.png"
import WaldverbandLogo from "../images/logos/waldverband vorarlberg logo.jpg"
import VorarlbergLogo from "../images/logos/Logo-auf-Weiss-RGB.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <Container fluid className="bg-secondary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start"
            >
              <img src={WkoLogo} className="img-fluid" alt="Wko Logo" />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className=" mt-5 align-content-end text-start"
            >
              <p>
                Die Wirtschaftskammern Österreichs vertreten mehr als 517.000
                Mitgliedsbetriebe. Als starke Stimme der Unternehmen setzen wir
                uns für eine zukunftsorientierte und wirtschaftsfreundliche
                Politik ein, z.B. für Steuerentlastung, Bürokratie-Abbau,
                Förderungen.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-primary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start"
            >
              <img src={TelesisLogo} className="img-fluid" alt="Telesis Logo" />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className=" mt-5 align-content-end text-start"
            >
              <p>
                Expertise und Technologie für Gesellschaft, Lebensraum und
                Wirtschaft. Wir denken lieber vor als nach und lieber mit als
                für Menschen. Wir bringen neue Perspektiven ein, um Potentiale
                unserer Kunden sichtbar zu machen. Wir schaffen Raum, um
                Bewährtes zu erhalten und Neues zu formen.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-secondary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start"
            >
              <img
                src={InterregLogo}
                className="img-fluid"
                alt="Interreg Logo"
              />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className="mt-5 align-content-end text-lg-start text-md-end text-sm-end text-xs-end"
            >
              <p>
                Interreg Europe helps regional and local governments across
                Europe to develop and deliver better policy. We create an
                environment and opportunities for sharing solutions and policy
                learning. We aim to make sure that government investment,
                innovation and implementation efforts all lead to integrated and
                sustainable impact for people and place.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-primary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start align-middle"
            >
              <img
                src={HfaLogo}
                className="img-fluid"
                alt="Holzverband Allgäu"
              />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className="align-content-end text-start align-middle"
            >
              <p>
                Im Holzforum Allgäu e.V. sind alle Mitglieder der
                Wertschöpfungskette vom Wald über Verarbeitung bis hin zur
                Planung vertreten. Der Verein fungiert als strategische Instanz
                und fachkompetentes Sprachrohr einer für das Allgäu so
                bedeutenden Branche. Das heimische Holz und die daraus
                entstehenden Produkte zu fördern, Weiterentwicklung
                voranzutreiben und die positiven Klimaschutzeffekte von Holz
                intensiver im Bewusstsein der Menschen zu verankern, ist die
                geteilte Zielsetzung aller Akteure.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-secondary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start"
            >
              <img
                src={WaldverbandLogo}
                className="img-fluid"
                alt="Waldverband Vorarlberg"
              />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className="align-content-end text-start align-middle"
            >
              <p>
                Die Serviceeinrichtung bietet für die Vorarlberger Waldbesitzer
                eine gemeinsame professionelle Rundholzvermarktung und nach
                Bedarf auch ein Komplettservice für die gesamte
                Waldbewirtschaftung an.
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-primary pt-5 pb-5">
        <Container>
          <Row className="text-white">
            <Col
              xs={12}
              s={12}
              md={12}
              lg={3}
              xl={3}
              className="mt-5 align-content-end text-lg-end text-md-start text-sm-start text-xs-start align-middle"
            >
              <img
                src={VorarlbergLogo}
                className="img-fluid"
                alt="Vorarlberg Land"
              />
            </Col>
            <Col
              xs={12}
              s={12}
              md={12}
              lg={6}
              xl={6}
              className="align-content-end text-start align-middle"
            >
              <p>
                Vorarlberg ist ein gebirgiges Bundesland im Westen Österreichs,
                das an Deutschland, Liechtenstein und die Schweiz angrenzt und
                für seine Skiresorts und seine Alpenlandschaft bekannt ist. Die
                Hauptstadt Bregenz liegt am Bodensee. Das moderne Kunsthaus
                zeigt zeitgenössische Kunst, während das Vorarlberg Museum die
                Geschichte und Kultur des Bundeslandes darstellt. Eine Seilbahn
                fährt hinauf zum Pfänder, der Blick auf die Stadt und ein Netz
                von Wanderwegen biete
              </p>
              <hr />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default IndexPage
